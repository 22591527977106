
// const tacticsItem=()=>import('@/components/tacticsItem/index.vue')
import tacticsItem from '@/components/tacticsItem/index.vue'
import {defineComponent, ref, reactive} from 'vue'
export default defineComponent({
  data() {
    return {
      tacticsItems: reactive([]), // 策略列表
    }
  },
  components: {
    "tactics-item": tacticsItem
  },
  setup(){
    
  },
  methods: {
    // 获取所有组合
    async getStrategys(){
      let res = await this.$api.getStrategys()
      this.tacticsItems = res
      console.log(this.tacticsItems)
    },

    // 跳转详情
    goDetail(item){
      // 目前先跳转雪球
      const url = `https://xueqiu.com/P/${item.strategy_id}`
      window.open(url, '_blank')
    },

    // 是否自己组合
    isMyself(id){
      let myself=['ZH2120024', 'ZH1741493']
      return myself.includes(id)
    }
  },
  mounted() {
    this.getStrategys()
  }
})
